<template>
  <v-app id="inspire">
    <v-main class="grey lighten-3">
      <v-container class="fill-height" fluid>
        <v-row align="center">
          <v-col align-self="center" class="d-flex justify-center">
            <v-card elevation="10" class="mx-auto">
              <v-img height="300" :src="immbg" class="white--text align-end">
                <v-card-title
                  ><v-icon color="white">mdi-police-badge</v-icon> Admin
                  Login</v-card-title
                >
              </v-img>
              <v-container>
                <v-alert type="error" v-if="errorMessage != ''">
                  {{ errorMessage }}
                </v-alert>
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-text-field
                    v-model="username"
                    :rules="usernameRules"
                    maxlength="10"
                    hint="enter username"
                    label="Username"
                    required
                  >
                  </v-text-field>

                  <v-text-field
                    v-model="password"
                    type="password"
                    :rules="passwordRules"
                    hint="enter your password"
                    label="Password"
                    required
                  ></v-text-field>

                  <v-btn
                    color="indigo darken-4"
                    class="pl-2 pr-2 mt-5"
                    block
                    dark
                    @click="login"
                    :loading="loading"
                    x-large
                  >
                    Login
                  </v-btn>
                </v-form>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import immbg from "../../assets/card_img1.png";
export default {
  data() {
    return {
      valid: false,
      immbg: immbg,
      username: "",
      usernameRules: [(v) => !!v || "กรอก Username"],
      password: "",
      passwordRules: [(v) => !!v || "กรอก Password"],
      loading: false,
      errorMessage: ""
    };
  },
  methods: {
    login() {
      if (!this.$refs.form.validate()) return;
      this.loading = true;
      const user = {
        username: this.username,
        password: this.password
      };
      this.$store
        .dispatch("login", user)
        .then(() => {
          this.$router.push({ name: "Dashboard" });
        })
        .catch((err) => {
          // console.log(err)
          this.errorMessage =
            err.error == "invalid_grant"
              ? "ไม่พบผู้ใช้งานนี้ในระบบ"
              : err.error;
          this.$refs.form.reset();
        })
        .finally(() => (this.loading = false));
    }
  }
};
</script>

<style></style>
